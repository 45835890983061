import { useContext } from "react";

import useParentsProps from "@/hooks/useParentsProps";
import { CheckoutContext } from "@/providers/context/CheckoutContext";

import { Text as ChakraText, TextProps } from "@chakra-ui/react";

interface PropTypes extends TextProps {
    type?: "heading" | "subHeading" | "normal";
    children?: React.ReactNode;
}

const Text = ({ type = "normal", children, ...textProps }: PropTypes) => {
    const { userSelectedLang: checkoutSelectedLang } =
        useContext(CheckoutContext);
    const { parentUser } = useParentsProps();

    const defaultLang = parentUser
        ? parentUser.languageId
        : checkoutSelectedLang;

    const tp = { ...textProps };
    tp.color = tp.color ?? "gray.800";
    switch (type) {
        case "heading":
            tp.fontFamily = "komu, sans-serif";
            tp.fontSize = tp.fontSize ?? [32, 40];
            tp.lineHeight = tp.lineHeight ?? ["35px", "41px"];
            // TODO: Refactor hardcoded condition
            tp.textTransform = defaultLang === "vi" ? "uppercase" : "none";
            break;
        case "subHeading":
            tp.fontFamily = tp.fontFamily ?? "infini, sans-serif";
            tp.fontWeight = "bold";
            tp.lineHeight = tp.lineHeight ?? ["24px", "30px"];
            tp.fontSize = tp.fontSize ?? [14, 16];
            break;
        case "normal":
            tp.fontFamily = tp.fontFamily ?? "inter, sans-serif";
            tp.lineHeight = tp.lineHeight ?? "30px";
            tp.fontSize = tp.fontSize ?? [14, 16];
    }

    return <ChakraText {...tp}>{children}</ChakraText>;
};

export default Text;
